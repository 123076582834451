<template>
  <v-row :no-gutters="$vuetify.breakpoint.xsOnly" align="start" class="my-2">
    <v-col cols="12" md="4" sm="6">
      <kurcc-image-input :image.sync="siteData.logo" :name="$vuetify.lang.t('$vuetify.pages.site.form.fields.logo')"
                         dense/>
    </v-col>
    <v-col class="mb-6" cols="12" md="4" sm="6">
      <kurcc-image-preview v-if="siteData.logo" :src="siteData.logo" alt="site logo"/>
    </v-col>
  </v-row>
</template>

<script>
import Site from '@/modules/app/models/site'

export default {
  name: 'KurccSiteStepperStep7Content',
  components: {
    KurccImageInput: () => import('@/modules/app/components/KurccImageInput'),
    KurccImagePreview: () => import('@/modules/app/components/KurccImagePreview')
  },
  props: {
    site: {
      type: Site,
      required: true
    }
  },
  computed: {
    siteData: {
      get () {
        return this.site
      },
      set (v) {
        this.$emit('update:site', v)
      }
    }
  }
}
</script>
